export const ErrorCustomMessages = {
    DEFAULT_5XX: 'Ooooops, Something went wrong. Please try again or contact us if the error persists',
    DEFAULT_NOT_5XX: 'Whoops, Something went wrong. Please try again or contact us if the error persists',
    DEFAULT_NETWORK_ERROR: "Oops, it looks like you're experiencing a network problem. Please try again or contact us if the error persists",
    EXPIRED: 'Sorry, you are not authorized to perform this action',
    VERIFY : 'You’ve reached the maximum login attempts. Contact us',
    UPLOAD_VIDEO: 'Couldn’t upload the video',
    UPLOAD_RESOURCE: 'Couldn’t upload the resource',
    PROJECT_NOT_EXISTS: "Project doesn't exists",
    COULD_NOT_GET_TOKEN: "Couldn’t get token",
    DISCORD_ERROR: 'There was an issue trying to connect with discord',
    YOTI_VERIFICATION_ERROR: 'We could not verify that you are under 19, please try again',
    YOTI_VERIFICATION_PENDING: 'Your verification is still processing',
    VGS_LOAD_ERROR: 'VGS Loaded error',
    USER_NOT_UUID: 'User not UUID',
    GOOGLE_PLACES_ERROR: 'Failed to load. Try to reload the page',
    GOOGLE_PLACES_TIMEOUT_ERROR: 'Time Failed to load. Try to reload the page',
    IDV_URL_NOT_EXISTS: 'Something went wrong with verification url',
    OPEN_PLAID_ERROR: 'Something went wrong trying to open Plaid, please try to reload the page and try again. If the issue persists, contact our support',
    CREATE_CHORE_TIMEOUT: 'It has taken a long time to get an answer',
    CREATE_CHORE_ROLL_BACK: 'Something went wrong with the chore state',
    DEBIT_CARD_CONTACT_NOT_EXISTS: "Oops! We're having a little trouble connecting your new debit card. Please reach out to our support team for assistance!",
    TIMEOUT_ERROR_NAME: "TimeoutError",
    WEBSOCKET_ERROR_CONNECTION: "Reconnecting to servers. If issues persist, refresh or contact support. Thanks for your patience.",
    CHECKOUT_REDIRECTION_ERROR: 'Something went wrong trying to redirect to checkout'
} as const;